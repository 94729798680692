.search {
    height: 72px;
    position: relative;
    margin: 24px 24px 24px 24px;
    border: 1px solid #222222;
    background-color: #F7F7F7;
    align-content: middle;
    min-width: 976px;
}

.clear-search-button {
    position: relative;
    float: right;
    margin-top: 12px;
    margin-bottom: 12px;
}

.search-button {
    position: relative;
    margin-top: 12px;
    margin-right: 16px;
    margin-bottom: 12px;
    float: right;
}