.date-picker {
  height: 48px;
  position: relative;
  border: 1px solid #222222;
  margin: 12px 16px 0px 16px;
  background-color: #ffffff;
  align-content: middle;
  padding-top: 10px;
  width: fit-content;
  float: left;
}

.calendar-icon {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-left: 8px;
  color: #6e7783;
}

.select-date-button {
  position: relative;
  margin-right: 12px;
  border: 0px;
  background-color: #ffffff;
  font-size: 16px;
}

ul {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #222222;
  margin: 0px 0px 0px 14px;
  list-style: none;
  padding-left: 16px;
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  color: #0074c8;
  font-size: 16px;
  width: fit-content;
  z-index: 1;
}

li {
  margin-bottom: 15px;
}

li:first-child {
  margin-bottom: 12px;
  color: #222222;
}

li:last-child {
  margin-bottom: 0px;
}

select {
  position: relative;
  margin-right: 12px;
  border: 0px;
  font-size: 16px;
}
