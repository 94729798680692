@import "~@alaskaairux/orion-design-tokens/dist/tokens/SCSSVariables";

.search-results-header-container {
  margin-left: 48px;
  margin-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  font-size: var(--auro-text-body-size-sm);
}

.search-results-header-chevron {
  width: 24px;
  height: 24px;

  &.inactive {
    filter: invert(45%) sepia(11%) saturate(436%) hue-rotate(175deg)
      brightness(98%) contrast(88%);
    fill: #6e7783;
  }

  &.active {
    filter: invert(20%) sepia(46%) saturate(7351%) hue-rotate(192deg)
      brightness(103%) contrast(102%);
    fill: #0074c8;
  }
}

.search-results-header-item {
  flex: 1 1 0px;

  &.leg {
    flex: 1 1 20%;
  }

  &.bookings {
    flex: 1 1 21%;
  }

  &.capacity {
    flex: 1 1 20.5%;
  }

  &.load {
    flex: 1 1 21%;
  }

  &.auth {
    flex: 1 1 5%;
  }
}
