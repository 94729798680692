.update-auth-level-input-container {
	margin-right: 80px;
}

.update-auth-level-input-label {
	margin-right: 8px;
	font-size: var(--auro-text-body-default-weight);
}

.update-auth-level-input {
	background-color: #FFF;
	border: 1px solid #222;
	border-radius: 5px;
	width: 48px;
	height: 32px;
	font-size: var(--auro-text-body-default-weight);
	text-align: center;

	&.changed {
		border: 1px solid #DF0B37
	}
}