@import "~@alaskaairux/orion-design-tokens/dist/tokens/SCSSVariables";

.update-history-list-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  font-size: var(--auro-text-body-default-weight);
  margin-top: 12px;
}

.update-history-list-item {
  flex: 1 1 0px;
  &.date {
    flex: 1 1 20%;
  }

  &.name {
    flex: 1 1 15%;
  }
}