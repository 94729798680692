@import "~@alaskaairux/orion-design-tokens/dist/tokens/SCSSVariables";

.update-auth-level-container {
	font-size: var(--auro-text-body-default-weight);
	background-color: #F7F7F7;
	border: 1px solid #222222;
}

.update-auth-level-container-body {
	margin: 26px 16px 6px 16px;
	display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.update-auth-level-container-body-input {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.update-auth-level-container-body-buttons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.update-auth-level-container-body-buttons-authorization {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.update-auth-level-authorization-text {
	margin-top: 4px;
	font-size: var(--auro-text-body-size-xs);
	color: green;

	&.error {
		color: #DF0B37;
	}
}