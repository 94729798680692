@import "~@alaskaairux/orion-design-tokens/dist/tokens/SCSSVariables";

.logo {
  height: 67px;
  width: 107px;
  margin-bottom: 15px;
}

.line {
  margin-left: 12.5px;
  margin-right: 12.5px;
  height: 60px;
  border-left: 1px solid white;
  position: absolute;
}

.link-style {
  color: white;
  &.main {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    margin-left: 28px;
    font-weight: 500;
    line-height: 1.2;
    text-decoration: none;
  }

  &.logout {
    position: absolute;
    right: 24px;
  }

  &.help {
    position: absolute;
    right: 116px;
  }
}

.app-header {
  font-family: Circular, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  background-color: #336787;
  color: #fff;
  padding: 26px 24px 26px 24px;
}

.label-sm {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  top: 34px;
}
