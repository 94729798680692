@import "~@alaskaairux/orion-design-tokens/dist/tokens/SCSSVariables";

.help-container {
  margin: 40px 42px 24px 42px;
  text-align: left;
}

.help-section {
  margin-top: 24px;
  margin-right: 40px;
}

.help-text {
  font-size: var(--auro-text-body-size-sm);
}

ul li a {
  color: #f8f8f8;
  text-decoration: none;
}
