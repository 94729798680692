@import "~@alaskaairux/orion-design-tokens/dist/tokens/SCSSVariables";

.search-results-chevron {
  width: 24px;
  height: 24px;
  filter: invert(45%) sepia(11%) saturate(436%) hue-rotate(175deg)
    brightness(98%) contrast(88%);
  fill: #6e7783;
}

.search-results-container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 12px;
  margin-right: 16px;
  font-size: var(--auro-text-body-default-weight);
}

.search-results-item {
  flex: 1 1 0px;

  &.leg {
    flex: 1 1 23.4%;
  }

  &.bookings {
    flex: 1 1 22%;
  }

  &.capacity {
    flex: 1 1 22%;
  }

  &.load {
    flex: 1 1 22%;
  }

  &.auth {
    flex: 1 1 7%;
  }
}
