.flight-search-input {
    height: 48px;
    border: 1px solid #222222;
    position: relative;
    float: left;
    margin: 12px 0 12px 0px;
    background-color: #FFFFFF;
    align-content: middle;
    padding-top: 10px;

}

.search-icon {
    position: relative;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    margin-left: 8px;
    color: #6E7783;
}

.flight-number-placeholder {
    position: relative;
    margin-right: 12px;
}

input {
    border: 0px;
    background-color: #FFFFFF;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #222222;
    opacity: 1; /* Firefox */
  }

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #222222;
}
  
::-ms-input-placeholder { /* Microsoft Edge */
    color: #222222;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* Firefox 18- */
input:focus::-moz-placeholder { color:transparent; } /* Firefox 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* oldIE ;) */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}