@import "~@alaskaairux/orion-design-tokens/dist/tokens/SCSSVariables";

.update-history-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  font-size: var(--auro-text-body-size-sm);
}

.update-history-header-chevron {
  width: 24px;
  height: 24px;

  &.inactive {
    filter: invert(45%) sepia(11%) saturate(436%) hue-rotate(175deg) brightness(98%) contrast(88%);
    fill: #6E7783;
  }

  &.active {
    filter: invert(20%) sepia(46%) saturate(7351%) hue-rotate(192deg) brightness(103%) contrast(102%);
    fill: #0074C8;
  }
}

.update-history-header-item {
  flex: 1 1 0px;
  &.date {
    flex: 1 1 20%;
  }

  &.name {
    flex: 1 1 15%;
  }
}